<template>
  <div class="footer-feedback">
    <div class="footer-feedback__title">{{ $t('ratingProduct.support') }}</div>

    <a class="footer-feedback__link" :href="helpLink">
      {{ $t('otherQuestions') }}
    </a>

    <a v-if="showFeedback" class="footer-feedback__messenger" @click.prevent="openFlomni">
      <message-icon class="footer-feedback__messenger-icon" />

      {{ $t('widgetContactChatButton') }}
    </a>

    <a
      v-if="phoneNumber"
      :href="`tel:${phoneNumber}`"
      class="footer-feedback__link footer-feedback__phone"
    >
      {{ phoneNumberFormatted }}
    </a>

    <a v-if="isRuSite" class="footer-feedback__city" @click="openCityModal">
      <footer-location />

      {{ globalCity.name }}
    </a>

    <div v-if="feedback.text" class="footer-feedback__link" v-html="feedback.text" />
  </div>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { CALL_PHONES } from '@/constants/city-phone-number';
import { openFlomni } from '@/composables/use-flomni';
import { computed, onMounted } from 'vue';
import useSiteInfo from '@/composables/use-site-info';
import { cdekConsoleError } from '@/utils/console-wrapper';
import messageIcon from '@/components/Footer/icons/message.svg';
import useSettings from '@/composables/use-settings';
import type { FooterFeedback } from '#root/src/types/store/footer';
import { useSelectGlobalCityModal } from '@/composables/use-select-global-city-modal';
import { useSelectedCity } from '@/composables/use-selected-city';
import type { CdekDefaultCity } from '@/types/dto/city';
import FooterLocation from './icons/footer-location.svg';

const props = defineProps<{
  feedback: FooterFeedback;
}>();

const store = useStore();
const displayChat = computed(() => store.getters['flomni/displayChat']);
const { globalCity, selectGlobalCity } = useSelectedCity();
const { openModal } = useSelectGlobalCityModal();

const { isRuSite, currentLocaleISO } = useSiteInfo();

const { getSettingsGroup } = useSettings();

const settings = getSettingsGroup('all');

const city = computed(() => {
  return store.getters.city;
});

const phoneNumber = computed(() => {
  if (isRuSite.value) {
    const phone = CALL_PHONES[city.value?.cdek_id];
    return phone || props.feedback.phone || '';
  }

  return props.feedback.phone || '';
});

const phoneNumberFormatted = computed(() => {
  if (isRuSite.value) {
    const phone = CALL_PHONES[city.value?.cdek_id]?.replace(
      /\+7(\d{3})(\d{3})(\d{2})(\d{2})/g,
      `+7 ($1) $2 $3 $4`,
    );
    return phone || props.feedback.phone || '';
  }

  return props.feedback.phone || '';
});

const helpLink = computed(() => {
  return `/${currentLocaleISO.value}/faq/`;
});

const openCityModal = () => {
  const onChange = (newCity: CdekDefaultCity) => {
    selectGlobalCity(newCity);
  };

  openModal(globalCity.value, onChange);
};

onMounted(() => {
  const script = document.querySelector('script[type="application/ld+json"]#organization');

  if (!script) {
    return;
  }

  try {
    const orgData = JSON.parse(script?.textContent ?? '');

    const orgDataNumber = orgData.contactPoint[0].telephone;

    const noSpacingPhoneNumber = phoneNumber.value.replace(/\s/g, '');

    if (orgDataNumber !== noSpacingPhoneNumber) {
      orgData.contactPoint[0].telephone = noSpacingPhoneNumber;

      script.textContent = JSON.stringify(orgData);
    }
  } catch (e) {
    cdekConsoleError(e, { dontSendSentry: true });
  }
});

const showFeedback = computed(() => {
  return displayChat.value || settings.displayHelpCustomer;
});
</script>

<style lang="scss" scoped>
.footer-feedback {
  min-width: 250px;

  &__title {
    @include headline-6;

    margin-bottom: 12px;
  }

  &__link {
    @include body-2;

    color: $Peak_80;
    text-decoration: none;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__phone {
    padding-bottom: 4px;
  }

  &__messenger {
    @include headline-5;

    padding: 16px 0;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    color: #fff;

    a {
      text-decoration: none !important;
    }

    &-icon {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
  }

  &__city {
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 14px;
  }
}
</style>
