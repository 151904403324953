<template>
  <div class="accordion">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { useAccordion } from '@/components/Accordion/composables/use-accordion';
import type { AccordionEmits, AccordionProps } from '@/components/Accordion/accordion-types';

const props = defineProps<AccordionProps>();
const emit = defineEmits<AccordionEmits>();

useAccordion(props, emit);
</script>

<style lang="scss" scoped>
.accordion {
  position: relative;
}
</style>
