<template>
  <div class="cookie-alert">
    <div class="cookie-alert__content">
      <div class="cookie-alert__content__text">
        <h3 class="cookie-alert__title" v-html="title" />
        <div class="cookie-alert__description" v-html="note" />
      </div>
      <div class="cookie-alert__button_wrapper">
        <cdek-button @click="onClick">{{ button }}</cdek-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { CdekButton } from '@cdek-ui-kit/vue';
import useSettings from '@/composables/use-settings';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import type { VueCookies } from 'vue-cookies';

const $cookies = inject<VueCookies>('$cookies');
const store = useStore();
const { getSettingsGroup } = useSettings();
const { t } = useI18n();

const cookiesSettings = getSettingsGroup('cookies');

const title = computed(() => {
  return cookiesSettings?.title || t('popupCookieMsg');
});
const note = computed(() => {
  return cookiesSettings?.text || t('popupCookieAgreement');
});
const button = computed(() => {
  return cookiesSettings?.btnText || t('toAccept');
});

const onClick = () => {
  store.dispatch('cookie-alert/agreeToUseOfCookies');
  $cookies?.set('consent-to-use-of-cookies', true, 60 * 60 * 24 * 7 * 356 * 30);
};
</script>

<style lang="scss" scoped>
.cookie-alert {
  background-color: $Peak;
  box-shadow: 0 -5px 18px 0 rgba(0, 0, 0, 0.05);
  padding: 16px;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 999;

  @include media-min-lg {
    padding: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include media-min-lg {
      flex-direction: row;
      justify-content: space-between;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  &__title {
    font-family: Roboto, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    color: $Bottom;

    @include media-min-lg {
      line-height: 24px; /* 150% */
      font-size: 16px;
    }
  }

  &__description {
    color: $Bottom;
    font-family: Roboto, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */

    @include media-min-lg {
      font-size: 14px;
      font-style: normal;
      line-height: 20px; /* 142.857% */
    }
  }

  &__button_wrapper {
    :deep(button) {
      border-radius: 10px;
      padding: 6px 12px;

      @include media-min-lg {
        padding: 12px 24px;
        width: 173px;

        &.cdek-button.small {
          height: 48px;
        }
      }
    }
  }
}
</style>
