import type { Ref } from 'vue';
import { ref } from 'vue';

export function useAccordionElement(isActive: Ref<boolean>) {
  const content = ref<HTMLDivElement | null>(null);
  const beforeEnter = (el: Element) => {
    if (el instanceof HTMLElement) {
      el.style.maxHeight = '0';
    }
  };
  const enter = (el: Element) => {
    if (el instanceof HTMLElement) {
      el.style.maxHeight = `${el.scrollHeight}px`;
      if (el.style.overflow !== 'visible') {
        setTimeout(() => {
          el.style.overflow = 'visible';
        }, 150);
      }
    }
  };
  const beforeLeave = (el: Element) => {
    if (el instanceof HTMLElement) {
      el.style.maxHeight = `${el.scrollHeight}px`;
    }
  };
  const leave = (el: Element) => {
    if (el instanceof HTMLElement) {
      el.style.maxHeight = '0';
      el.style.overflow = 'hidden';
    }
  };

  const updateSizeOnResize = () => {
    if (isActive.value && content.value) {
      enter(content.value as Element);
    }
  };

  return {
    content,
    beforeEnter,
    enter,
    beforeLeave,
    leave,
    updateSizeOnResize,
  };
}
