import { destroyFactory, getInstanceFactory, Multitone, useServiceFactory } from '@cdek-ui-kit/vue';
import { markRaw } from 'vue';

import { useRestApi } from '@/composables/use-rest-api';
import { useStore } from 'vuex';
import { cdekConsoleError } from '@/utils/console-wrapper';
import type { OfficeMapDto } from '@/types/dto/office';
import type { CdekDefaultCity } from '@/types/dto/city';
import type { CdekOfficeFilter } from '@/types/dto/cdek-office';
import debounce from 'lodash/debounce';
import merge from 'lodash/merge';
import { MoscowCenterCoords } from '@/constants/default-coords';
import { averageGeolocation } from '@/components/Offices/coordsUtils';

class OfficeData extends Multitone {
  private readonly restApi = markRaw(useRestApi().restApi);

  private readonly store = markRaw(useStore());

  private get locale() {
    return this.store.state.locale;
  }

  totalOfficesInCurrentCity = 0;

  offices: OfficeMapDto[] = [];

  officesLoading = false;

  centerCoords = MoscowCenterCoords;

  private _city = { ...this.store.getters.city };

  private _filters: Partial<CdekOfficeFilter> = {};

  private _extraFilters = {};

  public get city() {
    return this._city;
  }

  public set city(city) {
    this._city = city;
    this.updateDisplayedData(city);
  }

  public get filters() {
    return this._filters;
  }

  public set filters(filters) {
    this._filters = filters;
    this.updateOffices();
  }

  public get extraFilters() {
    return this._extraFilters;
  }

  public set extraFilters(extraFilters) {
    this._extraFilters = extraFilters;
    this.updateOffices();
  }

  private updateDisplayedData(city?: CdekDefaultCity) {
    const cityToSearch = city || this.city;

    const officesInSelectedCity = this.offices.filter(
      (office) => +office.cityCode === cityToSearch.cdek_id,
    );

    this.totalOfficesInCurrentCity = officesInSelectedCity.length;

    this.centerCoords = officesInSelectedCity.length
      ? averageGeolocation(officesInSelectedCity)
      : MoscowCenterCoords;
  }

  // Я не понял почему оно не работает с эрроу функцией
  updateOffices = debounce(async function (this: OfficeData, city?: CdekDefaultCity) {
    this.officesLoading = true;

    try {
      this.offices = await this.restApi.websiteOfficeMap(
        { locale: this.locale },
        merge({}, this.filters, this.extraFilters),
      );

      this.updateDisplayedData(city);
    } catch (e) {
      cdekConsoleError(e);
    } finally {
      this.officesLoading = false;
    }
  }, 100);
}

export const getOfficeDataService = getInstanceFactory<OfficeData>(OfficeData);

export const destroyOfficeDataService = destroyFactory(OfficeData);

export const useOfficeDataService = useServiceFactory<OfficeData>(getOfficeDataService, OfficeData);
