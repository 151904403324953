<template>
  <menu-popover-item-children v-if="menu.children?.length > 0" :menu="menu" />
  <menu-item
    v-else
    :menu="menu"
    :goal="menu.url.includes('inter') ? 'inter_header' : ''"
    class="menu-popover-item"
    :class="{ active: isActive, 'menu-popover-item__children': hasChildren }"
  >
    <div class="menu-popover-item__container">
      <div
        class="menu-popover-item__title"
        :class="{ 'menu-popover-item__title__children': hasChildren }"
      >
        <div v-html="menu.title" />

        <menu-new-label v-if="menu.isNew" class="menu-popover-item__label" />
      </div>
      <div v-if="menu.text" class="menu-popover-item__description" v-html="menu.text" />
    </div>

    <template v-if="hasIcons">
      <img v-if="menu.icon" :src="menu.icon" alt="" />
      <menu-placeholder-icon v-else alt="" class="menu-popover-item__icon" />
    </template>
  </menu-item>
</template>
<script setup lang="ts">
import MenuPlaceholderIcon from '#root/src/components/MobileDrawer/icons/mobile-drawer-placeholder-icon.svg';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useMenuStore } from '@/store/pinia/menu/menu';
import MenuNewLabel from '@/components/Menu/MenuNewLabel.vue';
import MenuItem from '@/components/Menu/MenuItem.vue';
import MenuPopoverItemChildren from '@/components/Menu/MenuPopoverItemChildren.vue';
import type IMenuItem from '@/types/dto/menuItem';

interface IMenuPopoverItemProps {
  menu: IMenuItem;
  hasIcons: boolean;
}

const props = defineProps<IMenuPopoverItemProps>();

const menuStore = useMenuStore();

const { activePath } = storeToRefs(menuStore);

const isActive = computed(() => {
  return activePath.value[2]?.id === props.menu?.id || activePath.value[3]?.id === props.menu?.id;
});

const hasChildren = computed(() => {
  return props.menu.children.length > 0;
});
</script>

<style scoped lang="scss">
.menu-popover-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px;
  transition: $primary-transition;
  text-decoration: none;
  border-radius: 8px;
  gap: 24px;

  img {
    width: 56px;
    height: 56px;
  }

  &__icon {
    max-width: 56px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 260px;
  }

  &__title {
    display: flex;
    gap: 8px;

    > div {
      color: #232323;
      font-family: Roboto, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }

    .menu-popover-item__label {
      max-height: 18px;
      margin-right: 4px;
    }
  }

  &__description {
    width: 260px;
    color: $grey-transparent;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &:hover:not(&__children),
  &.active:not(&__children) {
    background: $Primary_10;
  }
}
</style>
