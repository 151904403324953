<template>
  <div class="footer-application-links">
    <a
      v-for="(link, index) in linksToRender"
      :key="index"
      class="footer-application-links__item"
      :href="link.url"
      :target="link.target"
    >
      <img class="footer-application-links__icon" :src="link.image" />
    </a>
  </div>
</template>

<script lang="ts" setup>
import type { FooterApplicationLink } from '@/types/store/footer';
import { computed } from 'vue';

const props = defineProps<{
  links: FooterApplicationLink[];
}>();

const linksToRender = computed(() => {
  return props.links.filter((link) => link.image);
});
</script>
<style lang="scss" scoped>
.footer-application-links {
  display: flex;
  gap: 8px;
  flex-flow: row wrap;

  &__icon {
    max-height: 40px;
    width: auto;
  }
}
</style>
