<template>
  <footer class="footer" :class="footerClass">
    <base-container>
      <div class="footer__top">
        <footer-additional-links
          v-if="additionalLinks.length"
          :title="$t('additionally')"
          :links="additionalLinks"
        />

        <footer-additional-links
          v-if="officialLinks.length"
          :title="$t('agreements')"
          :links="officialLinks"
        />

        <div class="footer__feedback-wrapper">
          <footer-chinese v-if="isChine" />
          <footer-feedback v-else-if="footer.feedback" :feedback="footer.feedback" />
        </div>
      </div>

      <footer-international
        v-if="footer.links?.international?.length"
        class="footer__international"
        :international="footer.links.international"
      />

      <footer-application-links
        v-if="footer.links?.application?.length"
        class="footer__application"
        :links="footer.links.application"
      />

      <footer-copy :links="socialLinks" />
    </base-container>
  </footer>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { computed, defineAsyncComponent } from 'vue';
import usePage from '@/composables/use-page';
import BaseContainer from '@/components/BaseContainer.vue';
import FooterAdditionalLinks from '@/components/Footer/FooterAdditionalLinks.vue';
import FooterApplicationLinks from '@/components/Footer/FooterApplicationLinks.vue';
import FooterFeedback from '@/components/Footer/FooterFeedback.vue';
import FooterInternational from '@/components/Footer/FooterInternational.vue';
import FooterCopy from '@/components/Footer/FooterCopy.vue';

import type { FooterData } from '@/types/store/footer';

const FooterChinese = defineAsyncComponent(() => import('./FooterChinese.vue'));

const store = useStore();
const isChine = computed<boolean>(() => store.getters.isChineSite);

const footer = computed<FooterData>(() => store.getters['footer/footer']);

const additionalLinks = computed(() => {
  return footer.value.links?.additional ?? [];
});

const officialLinks = computed(() => {
  return footer.value.links?.official ?? [];
});

const socialLinks = computed(() => {
  return footer.value.links?.social ?? [];
});

const { isNewDesign, isSearchResultsPage } = usePage();
const footerClass = computed(() => {
  return {
    footer__enable_margin: isNewDesign.value || isSearchResultsPage.value,
  };
});
</script>

<style lang="scss" scoped>
.footer {
  padding: 48px 16px 32px;
  display: flex;
  flex: 1 1 auto;
  min-height: 36px;
  color: $Peak;
  background-color: #1c1b22;

  .base-container {
    width: 100%;
    padding: 0 !important;
  }

  @include media-min-lg {
    padding: 48px 16px;
  }

  @include media-min-xl {
    padding: 48px 0;
  }

  &__top,
  &__international {
    margin-bottom: 24px;

    @include media-min-md {
      margin-bottom: 48px;
    }
  }

  &__application {
    margin-bottom: 20px;
  }

  &__top {
    display: grid;
    gap: 24px;

    @include media-min-md {
      grid-template-columns: 1fr 1fr 2fr;
      gap: 64px;
    }
  }

  &__feedback-wrapper {
    @include media-min-lg {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.footer__enable_margin {
  margin-top: 48px;

  @include media-min-lg {
    margin-top: 80px;
  }
}
</style>
