import type { CdekDefaultCity } from '@/types/dto/city';
import useModal from '@/composables/use-modal';
import SelectGlobalCity from '@/components/Modal/SelectGlobalCityModal.vue';

export const useSelectGlobalCityModal = () => {
  const name = 'selectGlobalCityModal';

  const modal = useModal();

  const closeModal = () => {
    modal.hide(name);
  };

  const openModal = (city: CdekDefaultCity, onChange: (data: CdekDefaultCity) => void) => {
    modal.show({
      component: SelectGlobalCity,
      bind: {
        city,
      },
      on: {
        onSave: (data: CdekDefaultCity) => {
          onChange(data);
        },
      },
    });
  };

  return {
    name,
    openModal,
    closeModal,
  };
};
