<template>
  <header
    ref="el"
    :class="{
      'layout-header__sticky': isHelpPage,
    }"
  >
    <div class="device-wrapper show-on-mobile">
      <div class="top-line">
        <base-container>
          <div class="inner-wrapper">
            <a class="logo" :href="rootWithLocale" @click="onClick(menu[0])">
              <img v-if="logo?.src" :src="logo.src" alt="CDEK" />
            </a>

            <button class="mobile-toggle__button" @click="menuStore.openMobileMenu">
              <span class="mobile-toggle" :class="{ 'order-notification': ordersHasUpdates }">
                <mobile-drawer-icon />
              </span>
            </button>
          </div>
        </base-container>
      </div>

      <mobile-drawer :cabinet="cabinet" />
    </div>

    <div class="device-wrapper show-on-desktop">
      <base-container class="top-level">
        <div class="top-level__left">
          <a
            v-if="logo && logo.src"
            :href="rootWithLocale"
            class="top-level__logo"
            @click="onClick(menu[0])"
          >
            <img :src="logo.src" alt="CDEK" />
          </a>

          <div v-if="menu.length > 0" ref="menuContainer" class="top-level__container">
            <menu-item
              v-for="item in menu"
              :id="`top-menu-${item.id}`"
              :key="item.id"
              :ref="'menu' + item.id"
              :menu="item"
              class="top-level__link"
              :href="item.url"
              :class="{ active: activePath && activePath[0] && activePath[0].id === item.id }"
            >
              {{ item.title }}
            </menu-item>

            <div ref="underline" class="top-level__underline" />
          </div>
        </div>
        <div class="top-level__right">
          <icons-menu />
        </div>
      </base-container>

      <div class="top-level__spacer" />

      <base-container class="mid-level">
        <div v-if="search.isOpen" class="mid-level__search-text">
          <form @submit.prevent="doSearch">
            <cdek-input
              ref="searchText"
              v-model="search.text"
              class="mid-level__search-text-input"
              type="text"
              :placeholder="$t('menu.links.search')"
              clearable
              small
            >
              <template #icons-left>
                <search-icon-gray
                  class="mid-level__search-text-input__icon-left"
                  @click="doSearch"
                />
              </template>
            </cdek-input>
          </form>
        </div>
        <menu-mid-line v-else :menus="submenu" />
        <div class="mid-level__extra">
          <div class="mid-level__search-icons">
            <search-icon-close v-if="search.isOpen" @click="toogleSearch(false)" />
            <search-icon v-else @click="toogleSearch(true)" />
          </div>
          <locale-switcher v-if="displaySwitcher" class="mid-level__switcher" />
          <div v-if="isCabinet" id="header-cabinet-place" />
          <div class="mid-level__extra__buttons">
            <a v-if="isRuSite" :href="paymentLink">
              <cdek-button theme="outline" small class="mobile-drawer-first-level__payment">
                {{ $t('menu.links.payment') }}
              </cdek-button>
            </a>
            <client-only>
              <login v-if="enabledLK" />
            </client-only>
          </div>
        </div>
      </base-container>
    </div>
  </header>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch, nextTick, onBeforeUnmount } from 'vue';
import MobileDrawer from '#root/src/components/MobileDrawer/MobileDrawer.vue';
import BaseContainer from '#root/src/components/BaseComponents/BaseContainer.vue';
import { CdekButton, CdekInput } from '@cdek-ui-kit/vue';
import MenuMidLine from '@/components/Menu/MenuMidLine.vue';
import MenuItem from '@/components/Menu/MenuItem.vue';
import ClientOnly from '@/components/ClientOnly.vue';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import { useMenuStore } from '@/store/pinia/menu/menu';
import IconsMenu from '@/components/IconsMenu/IconsMenu.vue';
import useSiteInfo from '@/composables/use-site-info';
import usePage from '@/composables/use-page';
import useSettings from '@/composables/use-settings';
import searchIcon from '@/components/Search/img/search_fill.svg';
import searchIconGray from '@/components/Search/img/search.svg';
import searchIconClose from '@/components/Search/img/close.svg';
import { useStore } from 'vuex';
import Login from '@/components/Login/Login.vue';
import type IMenuItem from '@/types/dto/menuItem';
import { usePageContext } from '@/composables/use-page-context';
import { getSearchParams } from '#root/src/utils/search';
import MobileDrawerIcon from './icons/profile-icon.svg';

interface Logo {
  src?: string;
}

const props = withDefaults(
  defineProps<{
    logo: Logo;
    cabinet?: boolean;
  }>(),
  {
    cabinet: false,
  },
);

const menuStore = useMenuStore();

const { menu, activePath, individualsSubmenu, activeMenu } = storeToRefs(menuStore);

const { isRuSite, currentLocale, isLoggedInCabinet } = useSiteInfo();

const { isHelpPage, isSearchResultsPage } = usePage();

const paymentLink = computed(() => {
  return `/${currentLocale.value}/payment/`;
});

const rootWithLocale = computed(() => {
  return `/${currentLocale.value}/`;
});

const { getShowLangSwitcher } = useSettings();

const isShowLangSwitcher = getShowLangSwitcher();

const el = ref<HTMLElement | null>(null);
const underline = ref<HTMLElement | null>(null);
const menuContainer = ref<HTMLElement | null>(null);
const searchText = ref(null as unknown as InstanceType<typeof CdekInput>);

const { urlParsed } = usePageContext();
const store = useStore();

const ordersHasUpdates = computed(() => store.getters['cabinet/ordersHasUpdates']);
const enabledLK = computed(() => store.getters['auth/enabledLK']);

const activeFirstLevelMenu = computed(() => {
  return activePath.value[0] || null;
});

const submenu = computed(() => {
  if (activeFirstLevelMenu.value?.children?.length) {
    return activeFirstLevelMenu.value.children;
  }
  return individualsSubmenu.value;
});

const isCabinet = computed(() => {
  return props.cabinet;
});

const displaySwitcher = computed(() => {
  return !props.cabinet && isShowLangSwitcher;
});

const search = ref({
  text: urlParsed.search?.text || '',
  isOpen: isSearchResultsPage.value,
});

const updateActiveUnderline = () => {
  if (!activeFirstLevelMenu.value) {
    return;
  }

  const idx = activeFirstLevelMenu.value.id;

  if (!el.value) {
    return;
  }

  const currentMenu = el.value.querySelector(`#top-menu-${idx}`);

  if (currentMenu) {
    if (!underline.value) {
      return;
    }

    const rect = currentMenu.getBoundingClientRect();
    const containerRect = menuContainer.value?.getBoundingClientRect();

    underline.value.style.width = `${rect.width}px`;
    if (containerRect) {
      const translateX = rect.x - containerRect.x;
      underline.value.style.transform = `translateX(${translateX}px)`;
    }
  } else {
    setTimeout(updateActiveUnderline, 100);
  }
};

const loadCdekAuthScript = () => {
  const url = import.meta.env.VITE_CDEK_AUTH_SCRIPT_URL;

  if (isLoggedInCabinet) {
    return;
  }

  const script = Object.assign(document.createElement('script'), {
    async: true,
    src: url,
  });
  document.body.appendChild(script);
};

watch(() => activeMenu?.value, updateActiveUnderline, {
  immediate: true,
});

watch(isSearchResultsPage, () => {
  search.value.isOpen = isSearchResultsPage.value;
});

onMounted(() => {
  updateActiveUnderline();
  window.addEventListener('resize', updateActiveUnderline);
  loadCdekAuthScript();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateActiveUnderline);
});

const onClick = (menu: IMenuItem) => {
  menuStore.setActiveMenu(menu);
};

const toogleSearch = (value: boolean) => {
  search.value.isOpen = value;

  if (value) {
    nextTick(() => {
      searchText.value?.getControl().focus();
    });
  }
};

const doSearch = () => {
  if (search.value.text.length > 0) {
    document.location.href = `/${currentLocale.value}/search/?${getSearchParams(
      search.value.text,
    ).toString()}`;
  }
};
</script>

<style lang="scss" scoped>
.mid-level,
.top-level,
.top-level__container {
  display: flex;
  align-items: center;
}

.top-level {
  align-items: center;
  justify-content: space-between;

  &__underline {
    position: absolute;
    bottom: -1px;
    transition: $primary-transition;
    border-bottom: 2px solid $primary-base;
  }

  &__spacer {
    height: 1px;
    box-shadow: inset 0 -1px 0 $ui-gray;
  }

  &__link {
    padding: 12px;
    cursor: pointer;
    text-decoration: none;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $grey-transparent;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;

    &.active {
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    }
  }

  &__logo {
    display: inline-flex;
    flex: 0 0 auto;
    width: 72px;
    height: 20px;
    max-height: 28px;
    margin: auto 24px auto 0;

    & > img {
      width: 100%;
      height: auto;
    }
  }

  &__left {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    margin: auto;
  }
}

.mid-level {
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 22px;

  &__extra {
    display: flex;
    align-items: center;

    &__buttons {
      display: flex;
      gap: 16px;

      a {
        &:deep(.cdek-button) {
          @include button-1;

          width: 164px;
          padding: 6px 12px;
          letter-spacing: 0.4px;
        }
      }
    }
  }

  &__switcher {
    margin: 0 12px;
  }

  &__search {
    &-icons {
      padding-right: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &-text {
      width: 100%;
      margin: 0;
      padding: 0 14px 0 0;
      height: 36px;

      &-input {
        :deep(.cdek-input__tip) {
          display: none;
        }

        &__icon-left {
          cursor: pointer;
        }
      }
    }
  }
}

.mobile-toggle__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.mobile-toggle.order-notification {
  position: relative;

  &::after {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 12px;
    height: 12px;
    content: '';
    border: 2px solid $WhiteColor;
    border-radius: 12px;
    background: $ui-orange-base;
  }
}

.layout-wrapper {
  .layout-header__sticky {
    @include media-min-lg {
      position: sticky;
      top: 0;
    }
  }
}
</style>
