<template>
  <div class="login-button">
    <cdek-button
      v-if="enabledLoginBtn"
      v-test-id="'auth-login-btn'"
      small
      :loading="isLoading"
      @click="onLogin()"
    >
      {{ $t('logIn') }}
    </cdek-button>
  </div>
</template>

<script lang="ts" setup>
import { CdekButton } from '@cdek-ui-kit/vue';
import { useMenuStore } from '@/store/pinia/menu/menu';
import { storeToRefs } from 'pinia';
import useLogin from './use-login';

const menuStore = useMenuStore();
const { enabledLoginBtn } = storeToRefs(menuStore);
const { isLoading, onLogin } = useLogin();
</script>

<style lang="scss" scoped>
.login-button {
  min-width: 89px;
  width: max-content;

  :deep(.cdek-button) {
    padding: 6px 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }
}
</style>
