<template>
  <button class="help-customer-trigger" @click="onTrigger">
    <icon-close v-if="opened" :fill="'#fff'" />
    <icon-messages v-else class="help-customer-trigger__message" />
  </button>
</template>

<script setup lang="ts">
import { reachGoal } from '@/utils/reach-goal';
import IconMessages from './icons/messages.svg';
import IconClose from './icons/trigger-close.svg';

const props = defineProps<{
  opened: boolean;
}>();

const emit = defineEmits<{
  (e: 'toggle', data: boolean): void;
}>();

const onTrigger = () => {
  if (!props.opened) {
    reachGoal('widget_click');
  }

  emit('toggle', !props.opened);
};
</script>

<style scoped lang="scss">
.help-customer-trigger {
  background-color: $Primary;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
