<template>
  <div class="footer-copy">
    <div class="footer-copy__text">
      &copy;2000 — {{ currentYear }}, {{ $t('courierCompanyCdek') }}
    </div>

    <div class="footer-copy__media">
      <cdek-link v-for="(link, index) in linksToRender" :key="index">
        <a :href="link.url" :target="link.target">
          <img :src="link.image" alt="" :title="link.title" />
        </a>
      </cdek-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { FooterLink } from '@/types/store/footer';
import { CdekLink } from '@cdek-ui-kit/vue';
import { computed } from 'vue';

const props = defineProps<{
  links: FooterLink[];
}>();
const currentYear = new Date().getFullYear();

const linksToRender = computed(() => {
  return props.links.filter((link) => link.image);
});
</script>

<style lang="scss" scoped>
.footer-copy {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 20px;

  @include media-min-lg {
    justify-content: space-between;
    flex-direction: row;
  }

  &__text {
    @include body-2;

    color: $Peak_60;
  }

  &__media {
    display: flex;
    gap: 16px;
    padding-top: 16px;

    @include media-min-lg {
      padding-top: 0;
    }
  }
}
</style>
