<template>
  <div
    class="help-customer-container"
    :class="{
      'help-customer-container--open': open,
      'help-customer-container--flomni': isFlomni,
    }"
  >
    <div v-if="!loaded" class="help-customer-container__loading">
      <base-loader />
    </div>
    <div v-if="loaded" class="help-customer-container__loaded">
      <template v-if="(isRuSite || isAzSite) && firstScreen">
        <div class="help-customer-container__screen1">
          <div class="help-customer-container__main-info">
            <modal-header
              :label="$t('ratingProduct.support')"
              class="help-customer-container__mobile-header"
              with-close-button
              :with-back-button="false"
              @close="$emit('close')"
            />
            <help-customer-container-other v-if="!isAzSite" @click="otherClick" />
            <help-customer-container-messengers :opened="open" @close="$emit('close')" />
          </div>

          <help-customer-container-rating v-if="!isAzSite" :opened="open" />
        </div>
      </template>
      <template v-else>
        <help-customer-container-list
          v-if="!view && !isSystem"
          :list="list"
          :all-questions-link="allQuestionsLink"
          :opened="open"
          :widget="widget"
          :is-short-faq="isShortFaq"
          @select="onSelectView"
        />
        <help-customer-container-view v-if="view" :view="view" :opened="open" @back="onBack" />
        <help-customer-container-view-contact
          v-if="isSystem"
          :opened="open"
          :widget="widget"
          @back="onBack"
        />
        <div v-if="!isSystem" class="help-customer-container__footer" @click="onOpenSystem()">
          <button type="button" class="help-customer-container__all-questions">
            {{ widget && widget.allQuestionTitleResult }}
            <icon-arrow color="#000" direction="right" class="help-customer-container__icon" />
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseLoader from '@/components/BaseComponents/BaseLoader.vue';
import { defineAsyncComponent } from 'vue';
import { reachGoal } from '@/utils/reach-goal';
import { navigate } from 'vite-plugin-ssr/client/router';
import useSiteInfo from '@/composables/use-site-info';

const SCREENS = 2;

export default {
  name: 'HelpCustomerContainer',
  components: {
    ModalHeader: defineAsyncComponent(() =>
      import('@/components/Modal/BaseCdekModal/ModalHeader.vue'),
    ),
    HelpCustomerContainerList: defineAsyncComponent(() =>
      import('./HelpCustomerContainerList.vue'),
    ),
    HelpCustomerContainerView: defineAsyncComponent(() =>
      import('./HelpCustomerContainerView.vue'),
    ),
    HelpCustomerContainerViewContact: defineAsyncComponent(() =>
      import('./HelpCustomerContainerViewContact.vue'),
    ),
    HelpCustomerContainerRating: defineAsyncComponent(() =>
      import('./HelpCustomerContainerRating.vue'),
    ),
    HelpCustomerContainerMessengers: defineAsyncComponent(() =>
      import('./HelpCustomerContainerMessengersV2.vue'),
    ),
    HelpCustomerContainerOther: defineAsyncComponent(() =>
      import('./HelpCustomerContainerOther.vue'),
    ),
    IconArrow: defineAsyncComponent(() => import('../icon-svg/IconArrow.vue')),
    BaseLoader,
  },
  props: {
    opened: Boolean,
    loaded: Boolean,
    widget: { type: Object, default: null },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { currentLocale } = useSiteInfo();

    const { isRuSite, isAzSite } = useSiteInfo();

    const otherClick = () => {
      reachGoal('widget_FAQ');

      navigate(`/${currentLocale.value}/faq/`);

      emit('close');
    };

    return {
      otherClick,
      isRuSite,
      isAzSite,
    };
  },
  data() {
    return {
      open: false,
      view: null,
      isSystem: false,
      screen: new Array(SCREENS).fill(false),
      isShortFaq: import.meta.env.VITE_FAQ_STEP === 'short',
    };
  },
  computed: {
    ...mapGetters({
      isFlomni: 'flomni/displayChat',
    }),
    list() {
      return (this.widget && this.widget.questions) || [];
    },
    allQuestionsLink() {
      return (this.widget && this.widget.allQuestionsLink) || '';
    },
    chatLink() {
      return (this.widget && this.widget.chatLink) || '';
    },
    callLink() {
      return (this.widget && this.widget.callLink) || '';
    },
    firstScreen() {
      return this.screen[0];
    },
  },
  watch: {
    opened(opened) {
      this.open = opened;
      if (!opened) {
        this.reset();
      }
    },
  },
  mounted() {
    if (this.isShortFaq) {
      this.screen[1] = true;
      return;
    }
    this.screen[0] = true;
  },
  methods: {
    onOpenSystem() {
      reachGoal('widget_more');
      if (window.$flomni && window.$flomni.chat) {
        window.$flomni.chat.switchToggler(true);
        return;
      }

      this.view = null;
      this.isSystem = true;
    },
    onSelectView(item) {
      if (!item.isSystem) {
        this.view = item;
      } else {
        this.isSystem = true;
      }
    },
    onBack() {
      this.view = null;
      this.isSystem = false;
    },
    reset() {
      this.view = null;
      this.setCurrentScreen(this.isShortFaq ? 1 : 0);
    },
    goto(url) {
      this.$emit('close');
      this.$router.push(url);
    },
    setCurrentScreen(screenNumber) {
      for (let i = 0; i < SCREENS; i++) {
        this.screen[i] = false;
      }
      this.screen[screenNumber] = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import './variables';

.help-customer-container {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  display: none;

  &__screen1 {
    height: 100%;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $Peak;

    @include media-min-md {
      width: 396px;
      right: 24px;
      top: unset;
      left: unset;
      bottom: 88px;
      height: auto;
      background: transparent;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: visible;
    }
  }

  &__main-info {
    border-radius: 10px;
    background: $Peak;

    @include media-min-md {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    }
  }

  &:deep(.modal-header) {
    @include media-min-md {
      display: none;
    }
  }

  &--open {
    width: 380px;
    height: auto;
    display: block;
  }

  &__loaded {
    height: 100%;
    max-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &--flomni {
    right: -68px;

    @media (min-width: $tablet) {
      right: 0;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: $heightFooter;
    cursor: pointer;
    padding: 16px 30px 14px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__all-questions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;
    color: $black-dark;
    outline: none;
  }

  &__icon {
    margin-bottom: -2px;
  }
}
</style>
