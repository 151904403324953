import type { InjectionKey } from 'vue';
import { reactive, ref, readonly, provide, inject, watch, onBeforeUnmount, onMounted } from 'vue';
import type { AccordionEmits, AccordionProps } from '@/components/Accordion/accordion-types';
import type { Emitter } from 'mitt';
import { useAccordionElement } from '@/components/Accordion/composables/use-accordion-element';

const accordionInjectionKey = Symbol('accordion') as InjectionKey<{
  registerAccordionItem: (identifier: symbol) => boolean;
  deregisterAccordionItem: (identifier: symbol) => void;
  accordionItemsMap: Map<symbol, boolean>;
  toggleAccordionItem: (identifier: symbol) => void;
}>;

export const useAccordion = (props: AccordionProps, emit: AccordionEmits) => {
  const accordionItems = ref<symbol[]>([]);
  const hasModelValue = ref(props.modelValue !== undefined);
  const accordionItemsMap = reactive(new Map<symbol, boolean>());
  const registerAccordionItem = (identifier: symbol) => {
    const currentIdx = accordionItems.value.push(identifier) - 1;

    const isActive = hasModelValue.value ? +currentIdx === props.modelValue : false;

    accordionItemsMap.set(identifier, isActive);
    return isActive;
  };

  const deregisterAccordionItem = (identifier: symbol) => {
    accordionItems.value = accordionItems.value.filter((el) => el === identifier);
    accordionItemsMap.delete(identifier);
  };

  const toggleAccordionItem = (identifier: symbol, payload?: boolean) => {
    const idx = accordionItems.value.findIndex((el) => el === identifier);

    if (idx) {
      emit('update:modelValue', idx);
    }

    if (hasModelValue.value) {
      accordionItems.value.forEach((el) => {
        if (el === identifier) {
          return;
        }

        accordionItemsMap.set(el, false);
      });
    }

    accordionItemsMap.set(
      identifier,
      payload === undefined ? !accordionItemsMap.get(identifier) : payload,
    );
  };

  provide(accordionInjectionKey, {
    registerAccordionItem,
    deregisterAccordionItem,
    accordionItemsMap,
    toggleAccordionItem,
  });

  return {
    accordionItem: readonly(accordionItems),
    toggleAccordionItem,
  };
};

export const useAccordionItem = () => {
  const emitter = inject<Emitter<any>>('emitter');

  const accordionInjection = inject(accordionInjectionKey);

  if (!accordionInjection) {
    throw new Error('accordionItem was not provided');
  }

  const { registerAccordionItem, deregisterAccordionItem, toggleAccordionItem } =
    accordionInjection;

  const accordionItemSymbol = Symbol('');

  const defaultActive = registerAccordionItem(accordionItemSymbol);

  const openThisAccordionItem = () => {
    toggleAccordionItem(accordionItemSymbol);
  };

  const isActive = ref(defaultActive);

  watch(
    () => accordionInjection.accordionItemsMap,
    () => {
      const active = accordionInjection.accordionItemsMap.get(accordionItemSymbol);

      if (active === undefined) {
        return;
      }

      isActive.value = active;
    },
    {
      deep: true,
    },
  );
  const removeThisAccordionItem = () => {
    deregisterAccordionItem(accordionItemSymbol);
  };

  const { updateSizeOnResize } = useAccordionElement(isActive);

  onMounted(() => {
    updateSizeOnResize();
    window.addEventListener('resize', updateSizeOnResize);

    if (!emitter) {
      return;
    }

    emitter.on('updateAccordionSize', updateSizeOnResize);
  });

  onBeforeUnmount(() => {
    removeThisAccordionItem();
    window.removeEventListener('resize', updateSizeOnResize);

    if (!emitter) {
      return;
    }

    emitter.off('updateAccordionSize');
  });

  return {
    isActive,
    openThisAccordionItem,
    removeThisAccordionItem,
  };
};
