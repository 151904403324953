<template>
  <div class="icons-menu">
    <icons-menu-item href="/search" class="icons-menu__search" @click="closeMenu">
      <template #icon><searchIconGray /></template>
      {{ $t('menu.links.search') }}
    </icons-menu-item>
    <icons-menu-item v-if="isRuSite" :href="sendAsLegalLink" @click="onClickSendAsLegalHandler">
      <template #icon><trackIcon /></template>
      {{ $t('menu.links.sendAsLegal') }}
    </icons-menu-item>

    <icons-menu-item :href="officesLink" @click="closeMenu">
      <template #icon><mapPinIcon /></template>
      {{ $t('menu.links.offices') }}
    </icons-menu-item>

    <icons-menu-item v-if="helpEnabled" :href="helpLink" @click="closeMenu">
      <template #icon><helpIcon /></template>
      {{ $t('menu.links.help') }}
    </icons-menu-item>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import IconsMenuItem from '@/components/IconsMenu/IconsMenuItem.vue';
import { useMenuStore } from '@/store/pinia/menu/menu';
import useSiteInfo from '@/composables/use-site-info';
import { reachGoal } from '@/utils/reach-goal';
import searchIconGray from '@/components/Search/img/search.svg';
import trackIcon from './icons/track.svg';
import mapPinIcon from './icons/map-pin.svg';
import helpIcon from './icons/help.svg';

const menuStore = useMenuStore();

const { isRuSite, currentLocaleISO } = useSiteInfo();

const sendAsLegalLink = computed(() => {
  return `/${currentLocaleISO.value}/B2C-B2B-FORM/`;
});

const officesLink = computed(() => {
  return `/${currentLocaleISO.value}/offices/`;
});

const helpEnabled = computed(() => {
  return isRuSite.value;
});

const helpLink = computed(() => {
  return `/${currentLocaleISO.value}/faq/`;
});

const closeMenu = () => {
  menuStore.closeMobileMenu();
};

const onClickSendAsLegalHandler = () => {
  reachGoal('send_as_business_header');
  closeMenu();
};
</script>

<style scoped lang="scss">
.icons-menu {
  display: flex;
  flex-direction: column;

  @include media-min-xl {
    flex-direction: row-reverse;
  }

  &__search {
    display: flex;

    @include media-min-xl {
      display: none;
    }
  }
}
</style>
