<template>
  <accordion class="footer-accordion">
    <accordion-item class-name="footer-accordion-item">
      <template #header>
        {{ $t('internationalCdekSite') }}
      </template>

      <template #actions>
        <footer-arrow />
      </template>

      <div class="footer-international">
        <a
          class="footer-international__link"
          v-for="(link, idx) in international"
          :key="idx"
          :href="link.url"
          :target="link.target"
        >
          {{ link.title }}
        </a>
      </div>
    </accordion-item>
  </accordion>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { FooterLink } from '#root/src/types/store/footer';
import Accordion from '../Accordion/Accordion.vue';
import AccordionItem from '../Accordion/AccordionItem.vue';
import FooterArrow from './icons/footer-arrow.svg?component';

const props = defineProps<{
  international: FooterLink[];
}>();

const amountOfColumns = 4;

const amountOfRows = computed(() => {
  return Math.ceil(props.international.length / amountOfColumns);
});
</script>

<style lang="scss" scoped>
.footer-international {
  display: flex;
  flex-wrap: wrap;
  gap: 2px 8px;

  @include media-min-md {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(v-bind(amountOfRows), 1fr);
    display: grid;
    grid-auto-flow: column;
    gap: 2px 64px;
  }
}

.footer-international__link {
  @include body-2;

  color: $Peak_80;
  text-decoration: none;
}
</style>
