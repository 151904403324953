export const CALL_PHONES: Record<number, string> = {
  274: '+73852571105',
  286: '+74162210407',
  402: '+78182430405',
  432: '+78512740405',
  256: '+73472665405',
  337: '+74722407705',
  220: '+74832300605',
  230: '+73012570405',
  94: '+74922604405',
  426: '+78442515004',
  246: '+78172580405',
  506: '+74732290645',
  914: '+74262270450',
  231: '+73022710405',
  164: '+74932574905',
  281: '+73952640455',
  1081: '+78662229105',
  152: '+74012524005',
  207: '+78472295504',
  142: '+74842401405',
  285: '+74152340406',
  450: '+78142290405',
  272: '+73842670605',
  415: '+78332490504',
  404: '+78212720405',
  165: '+74942419237',
  435: '+78612920305',
  278: '+73912155604',
  93: '+73522672005',
  699: '+74712746405',
  137: '+78129950405',
  320: '+74742242005',
  314: '+74132210406',
  141: '+78362344005',
  417: '+78342375504',
  265: '+78152780296',
  943: '+78182432164',
  414: '+78312830406',
  139: '+78162960405',
  270: '+73833756405',
  268: '+73812294325',
  261: '+73532920405',
  149: '+74862633405',
  504: '+78412390406',
  248: '+73422870405',
  288: '+74234244050',
  393: '+78112610405',
  438: '+78632790705',
  159: '+74912513405',
  430: '+78462140406',
  428: '+78452930405',
  283: '+74112260405',
  473: '+74242320405',
  250: '+73433615004',
  1082: '+78672284005',
  395: '+74812510405',
  439: '+78652210345',
  298: '+74752509945',
  424: '+78432450406',
  245: '+74822710405',
  269: '+73822977305',
  150: '+74872793405',
  252: '+73452610405',
  224: '+73412775629',
  422: '+78422760450',
  287: '+74212900406',
  625: '+73467223010',
  259: '+73515160405',
  419: '+78352380405',
  677: '+73492255107',
  146: '+74852680405',
  431: '+78482625004',
  437: '+78622910705',
  258: '+73519598106',
  273: '+73843601045',
  433: '+78552780456',
  44: '+74950090405',
};
