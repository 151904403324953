<template>
  <div
    :class="containerClasses"
    :aria-expanded="tab.isActive as unknown as boolean"
    @keydown.enter="tab.openThisAccordionItem()"
  >
    <button :class="classes.header" @click="tab.openThisAccordionItem">
      <div :class="classes.title">
        <slot name="header" />
      </div>

      <div :class="classes.actions">
        <slot name="actions" :is-active="tab.isActive.value">
          <accordion-arrow :class="classes.arrowIcon" />
        </slot>
      </div>
    </button>

    <transition
      name="accordion"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <!-- eslint-disable-next-line vue/require-toggle-inside-transition -->
      <div ref="content" :class="[classes.containerAnimate, 'accordion-item-animation']">
        <div :class="classes.container">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onBeforeUnmount, onMounted, watch } from 'vue';
import { useAccordionElement } from '@/components/Accordion/composables/use-accordion-element';
import type { Emitter } from 'mitt';
import { useAccordionItem } from '@/components/Accordion/composables/use-accordion';
import AccordionArrow from './icons/accordion-arrow.svg';

const emitter = inject<Emitter<any>>('emitter');

const tab = useAccordionItem();
const { content, beforeEnter, enter, beforeLeave, leave, updateSizeOnResize } = useAccordionElement(
  tab.isActive,
);

const props = withDefaults(defineProps<{ className?: string }>(), { className: 'accordion-item' });

const containerClasses = computed(() => {
  return {
    [`${props.className}`]: true,
    [`${props.className}__container--active`]: tab.isActive.value,
  };
});

const classes = computed(() => {
  return {
    header: `${props.className}__header`,
    title: `${props.className}__title`,
    actions: `${props.className}__actions`,
    arrowIcon: `${props.className}__arrow-icon`,
    containerAnimate: `${props.className}__container-animate`,
    container: `${props.className}__container`,
  };
});
watch(
  () => tab.isActive.value,
  (val) => {
    if (!content.value) {
      return;
    }

    if (val) {
      enter(content.value as Element);
    } else {
      leave(content.value as Element);
    }
  },
);

onMounted(() => {
  updateSizeOnResize();
  window.addEventListener('resize', updateSizeOnResize);

  if (!emitter) {
    return;
  }

  emitter.on('updateAccordionSize', updateSizeOnResize);
});

onBeforeUnmount(() => {
  tab.removeThisAccordionItem();
  window.removeEventListener('resize', updateSizeOnResize);

  if (!emitter) {
    return;
  }

  emitter.off('updateAccordionSize');
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/base-accordion';

.accordion-item-animation {
  overflow: hidden;
  transition: max-height 0.15s ease-in;
  max-height: 0;
}
</style>
